import { AppProps } from "next/app";
import Head from "next/head";
import * as React from 'react'
import { Provider } from "react-redux";
import { QueryClientProvider, QueryClient } from "react-query";
import { store } from "app/store";

import "styles/globals.css";
import 'styles/react-day-picker/style.css';

function App({ Component, pageProps }: AppProps) {
  const queryClient = new QueryClient();
  return (
    <>
      <Head>
        <title>AI Employment Agency</title>
        <link rel="shortcut icon" href="/favicon.png" />
        <meta name="insight-app-sec-validation" content="5ff7266c-f29b-4b20-8875-20935bd7edf4" />
        <meta httpEquiv="Content-Security-Policy"
          content="default-src * 'unsafe-inline'; script-src 'self' www.google-analytics.com https://browser.sentry-cdn.com https://js.sentry-cdn.com https://*.sentry.io https://*.ingest.sentry.io https://cdn.up2tom.com https://api.up2tom.com 'unsafe-inline'; connect-src * https://cdn.up2tom.com https://api.up2tom.com sentry.io *.sentry.io https://*.ingest.sentry.io; style-src 'self' https://fonts.googleapis.com 'unsafe-inline'; font-src 'self' https://fonts.gstatic.com; base-uri 'self'; img-src * data:" />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8; X-Content-Type-Options=nosniff" />
      </Head>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <React.StrictMode>
            <Component {...pageProps} />
          </React.StrictMode>
        </QueryClientProvider>
      </Provider>
    </>
  );
}

export default App;
